export default {
  home_headtitle: "Welcome to Liberty Cannabis",
  home_title: "Home",
  test_title: "Test page",
  learn_title: "Learn",
  about_title: "About",
  rewards_title: "Rewards",
  shop_title: "Shop",
  search_title: "Search",
  page_not_found_title: "Page Not Found",
  go_to: "GO TO",
  hello_from: "Hello From",
  home: "HOME",
  order_now: "ORDER NOW",
  order_nowPA: "RESERVE AHEAD",
  about: "ABOUT",
  become_a_patient: "BECOME A PATIENT",
  learn: "LEARN",
  rewards: "REWARDS",
  contact_us: "Contact Us",
  blog: "BLOG",

  /**Home Page */
  invalid_zip: "Please enter valid zipcode.",
  something_wrong: "Something went wrong.",
  /** */

  /* Home Page Not Close page*/
  browse_pages:
    "Browse these popular pages or select a location from the list above to learn more.",
  back_to_home: "BACK TO HOME",
  email_confirmation: "Thanks for signing up! We’ll be in touch soon.",
  /* */

  /**Footer */
  sign_up_updates: "Sign up for updates",
  thank_you: "Thanks for signing up! We’ll be in touch soon.",
  privacy_policy: "Privacy Policy",
  terms_service: "Terms of Service",
  copyright: "Copyright © Liberty Cannabis 2022",
  invalid_email: "Please enter valid email to continue.",
  copyright_number: "C12-0000193-LIC; C10-0000770-LIC; C10-0000581-LIC",

  // Location Picker
  dispensary_near_you: "Is there a Liberty dispensary near you?",
  enter_zip: "Enter zip code here.",
  go: "GO",
  are_you_21: "Are you 21 or older?*",
  i_am_old_enough: "I’M OLD ENOUGH",
  i_am_not: "I’M NOT",
  pennsylvania_and_maryland_notice:
    "*For Pennsylvania and Maryland you must be 18+.",
  agree_to: "Agree to",
  concentrates: "Concentrates",
  about_concentrates: "Higher potency products for experienced users",
  flower: "FLOWER",
  about_flower: "Indica, sativa, or hybrid—strains that fit every mood",
  devices: "DEVICES",
  about_devices:
    "Full range of tabletop and portable vaporizers and cartridges",
  edibles: "Edibles",
  about_edibles: "Cannabis-infused foods—discreet and portable",
  oils: "Oils",
  about_oils:
    "Made from premium cannabis; converted into a potent and therapeutic liquid",
  about_content_title: "How do you take your cannabis?",
  about_content_message:
    "Shop a variety of flower, vapes, concentrates, edibles, and more through your local Liberty location.",

  join_community: "Join the cannabis community",
  earn_rewards: "EARN LIBERTY REWARDS",
  dream: "A cannabis lover’s dream",
  know_before: "KNOW BEFORE YOU GO",
  faqs: "FAQs about your local dispensary",
  testimonial:
    "Great customer service. [Liberty’s] curbside pickup during this pandemic is second to none.",
  testimonial_by: "- Michael, Philadelphia",

  blog_title: "Blog",
  read_more: "READ MORE",
  sorry: "SORRY",
  you_must_be_over_age: "You must be at least 21 years old to enter the site.",
  please_comeback_when_you_are: "Please come back for a visit when you are.",
  back: "BACK",
  result_not_found_title: "Not finding what you’re looking for?",
  result_not_found_description:
    "Try a different search, browse these popular pages, or contact your local dispensary directly.",
  not_close_msg: "WISH YOU WERE CLOSER...",
  enter_email: "Enter your email",
  stay_touch: "STAY IN TOUCH",
  not_close_detail1:
    "Unfortunately, there are currently no Liberty dispensaries within ",
  not_close_detail2:
    "miles of your location, but we’re expanding quickly. Be the first to know if a location opens near you.",
  all_dispensaries: "All Dispensaries",
  all_locations: "All Locations",
  store_hours: "Store Hours:",
  make_my_store: "Make this my store",
  our_locations: "Our locations",
  shop_now: "SHOP NOW",

  // Become a Patient
  bpa_title: "Become a Patient",
  bpa_desc:
    "Are you looking for a medical marijuana card in your state? Find out the requirements for becoming a cannabis patient in select states below.",
  bpa_note_title: "PLEASE NOTE: ",
  bpa_note:
    "We do our best to keep things up to date. But the information here is subject to change based on local and federal laws. Please check with your state for the latest information. Proof of state residency and other forms of identification are required.",
  bpa_select_placeholder: "Select a state",
  california: "California",
  maryland: "Maryland",
  massachusetts: "Massachusetts",
  michigan: "Michigan",
  pennsylvania: "Pennsylvania",
  register_ca: "Register for the program by completing this ",
  form: " form.",
  recommendation_ca: "Get a written recommendation from your doctor for an ",
  register: "Register for the ",
  program: "program.",
  get_proof: "Get proof of an ",
  approved: "approved medical condition.",
  approved_mi: "approved medical condition",
  apply: "Apply ",
  in_person: "in-person",
  for_id: " for a medical marijuana ID card.",
  from_doctor: " from your doctor.",
  pay_for_id: "Pay for a medical marijuana ID card.",
  pay_for_id_mi: "Pay for a medical marihuana ID card.",
  get_medical: "Get medical marijuana from an ",
  approved_disp: "approved dispensary",
  thatsus: "that’s us!",
  visit_md: "Visit a licensed Maryland dispensary",
  register_email: "Register by mail or ",
  electronically: "electronically.",
  need_pin: "You will need your PIN number to complete ",
  registration: "registration.",
  register_md:
    "Register for the program. Info for adults and caregivers can be found ",
  here: "here.",
  more_info: "More info",
  primary_doctor: "from your primary doctor.",
  mi_cert:
    "Get a written certification from a registered medical marijuana doctor.",
  mi_license: "Get medical marijuana from a licensed provisioning center",

  // Page Not Found
  not_found_page_subheading: "Oops!",
  not_found_page_heading: "Page not found",

  // Exit Popup
  heads_up: "Heads up…",
  exit_msg: "You’re leaving our website ",
  exit_link: "LibertyCannabis.com",
  exit_msg2: ". Stop back and shop when you’re done.",

  /**Location picker */
  location_picker_ques: "Where are you trying to shop?",
  location_picker_msg: "Enter a zip code to find the nearest",
  location_picker_msg_link: "Liberty location",
  location_no_suggestion_msg:
    "It appears that Liberty isn’t carried within 25 miles of your location.",

  /* About Page */
  aboutpage_banner_title: "Life. Love. Liberty.",
  aboutpage_banner_subtitle:
    "We're driven by our passion for cannabis and our commitment to the values that keep us growing in the right direction.",
  aboutShopSectionTitle: "It's just better with cannabis",
  aboutShopSectionDescription:
    "From Blueberry Skunk to Candy Apple, we want you to explore every wonderous strain nature has given us. That's why our cannabis is hand-picked to give you your desired effect.",
  aboutShopLinkTitle: "Shop Liberty Cannabis",
  aboutShopLinkUrl: "/shop/",
  aboutRewardsSectionTitle: "Liberty Rewards",
  aboutRewardsSectionDescription:
    "Whether you're a new or an existing Liberty lover, Liberty is better because of patients like you. Life is better with $1 eighths-among other benefits of Liberty Rewards.",
  aboutRewardsLinkTitle: "Get Liberty Rewards",
  aboutRewardsLinkUrl: "/rewards/",
  aboutGrowSectionTitle: "How we grow responsibly",
  aboutGrowSectionDescription:
    "When nature gives you cannabis, you roll with it. At Liberty, we believe that cannabis can cultivate better health, prosperity, and communities. For us, responsible growth starts with integrity and diversity.",
  aboutGrowSectionDescription1:
    "When you choose Liberty, you're supporting a company nurtured by a responsible and progressive leader in cannabis:",
  aboutGrowSectionDescriptionCompany: "Holistic Industries",
  aboutGrowSectionDescriptionLink: "https://www.holisticindustries.com",
  aboutGrowLinkText: "Grow with us",
  aboutGrowLinkURL: "https://careers.jobscore.com/careers/holisticindustries/",
  aboutGrowSectionDescription2:
    "So you can feel good, not just because our cannabis brands free your mind but because they're also putting in the work.",
  aboutCaresSectionTitle: "Liberty Cannabis Cares",
  aboutCaresSectionDescription:
    "Liberty Cannabis Cares is the corporate social responsibility promise of Holistic Industries. From Day 1, we were on a mission-inspired by the stories and struggles of individuals, families, and communities across the country. In every state we operate in, in every neighborhood we're lucky to be a part of, we think beyond the business of cannabis and work toward positive social change.",
  aboutCaresLinkTitle: "Learn more about our impact",
  aboutCaresLinkUrl: "https://www.holisticindustries.com/impact",
  aboutBudSectionTitle: "We're here to be your bud",
  aboutBudSectionDescription:
    "How do you take your cannabis? We're here to help. But take your time. There's no rush. Life's about the journey, not the destination. Individualized conversations and exceptional experiences. It's the Liberty way.",
  aboutBudSectionLocationTitle: "Our locations",
  aboutBudSectionLocationDescription:
    "We keep growing! Choose your go-to Liberty location from the menu below.",
  aboutBudSectionLocationDescription2:
    "Your current location isn’t set. Enter your ZIP code to find the dispensary closest to you",
  aboutStoreSelectionOption: "Select a dispensary",
  aboutStoreSelectionButtonTitle: "Make this my store",
  aboutStoreSelectionViewSectionTitle: "Your current selected location:",
  aboutStoreSelectionViewTitle: "Liberty Cannabis",
  aboutStoreSelectionViewHoursTitle: "Store hours:",
  aboutUserNotSelectedLocation: "The users location has not been established",
  aboutModalTitle: "Heads up...",
  aboutModalSubTitle: "You're about to change your dispensary location.",
  aboutModalCancelButtonText: "Cancel",
  aboutModalContinueButtonText: "Continue",
  aboutImg1: "Isolated Bud",
  aboutImg2: "Strane",
  aboutImg3: "Do Drops",
  aboutImg4: "Garcia Handpicked",
  aboutImg5: "PAX",
  aboutImg6: "Liberty Cannabis Staff",
  aboutImg7: "Liberty Cannabis Staff",
  aboutImg8: "Liberty Rewards and Isolated Bud",
  aboutImg9: "Liberty Rewards and Isolated Bud",
  aboutImg10: "",
  aboutImg11: "",

  /* Learn Page */
  learnShootEmailUrl: "/contact-us/#contact-us-form-tag",
  learnForNewPatientTitle: "For new patients",
  learnDiscountTitle: "Discounts",
  learnDiscountDescription:
    "Liberty is proud to offer 20% off for Veterans, 10% off for Senior Citizens (60+), 10% off for 'Medicaid/Medicare (SSI/SSDI)' (not just 'Medicaid' patients), and 10% off for Industry Employees. Discounts cannot be combined with any other offers or discounts. Note: Price shown does not reflect any price adjustments, tiered pricing, or discounts/promotions that may be applied at the time of pickup.",
  learnMADiscountDescription:
    "Liberty is proud to offer 20% off for Veterans, 10% off for Senior Citizens (60+), 10% off for Financial Hardship, 10% off for Industry Employees, 30% off for Hospice, and 10% off for Pediatric Patients. Discounts cannot be combined with any other offers or discounts. Note: Price shown does not reflect any price adjustments, tiered pricing, or discounts/promotions that may be applied at the time of pick-up.",
  learnMDDiscountDescription:
    "Liberty is proud to offer 22% off for Veterans, 10% off for Senior Citizens (60+), 10% off for “Medicaid/Medicare (SSI/SSDI)” (not just “Medicaid” patients), and 10% off for Industry Employees. Discounts cannot be combined with any other offers or discounts. Note: Price shown does not reflect any price adjustments, tiered pricing, or discounts/promotions that may be applied at the time of pick-up.",
  learnDiscountButtonTitle: "Shop Now",
  learnDiscountButtonUrl: "/shop/",
  learnRewardsTitle: "Rewards",
  learnRewardsDescription:
    "Liberty Rewards is the easiest way to get rewarded for doing what you already love: Shopping at Liberty.#BR If you've already joined, it's easy to check your rewards points by visiting our Rewards page to log in and view your balance. You can also scan the QR code located at any of our dispensaries.",
  learnRewardsButtonTitle: "Learn More",
  learnRewardsButtonUrl: "/rewards/",
  learnGeneralFAQsTitle: "General Dispensary FAQs",
  learnMoreQuestionsTitle: "More questions?",
  learnMoreQuestionsDescription: "Get in touch!",
  learnMoreQuestionsURL: "/contact-us/",
  learnBannerDescription:
    "Explore the topics below for answers to the questions we get the most.",
  learnKnowBeforeYoGoTitle: "Know before you go",

  /* Rewards Page */
  rewardsBanner1: "Life with cannabis is",
  rewardsBanner2: "rewarding",
  rewardsBanner3: "Life with Liberty Rewards is even better*",
  rewardsBanner4:
    "Liberty Rewards is our way of saying thank you for trusting us with your dispensary experience. It's also a great way to get more of your favorite products or explore something new.",
  rewardsListTitle: "Every cannabis lover's dream",
  rewardsCurrency: "$",
  rewardsPrice1: "1",
  rewardsPrice2: "5",
  rewardsPrice3: "10",
  rewardsPrice4: "15",
  rewardsText1: "eighth of house-choice flower",
  rewardsText2: "0.5g concentrate",
  rewardsText3: "0.5g oil cartridge or disposable pen",
  rewardsText4: "PAX pod",
  rewardsText5: "coupon toward any purchase",
  rewardsImageAlt1: "Cannabis Concentrate",
  rewardsImageAlt2: "Cannabis oil cartridges",
  rewardsImageAlt3: "Isolated Cannabis Bud",
  rewardsImageAlt4: "Rewards in mobile",

  rewardsSignUpBoxTitle: "Sign up...",
  rewardsSignUpBoxContent:
    "It's easy to sign up and earn rewards with every purchase",
  rewardsEnjoyBoxTitle: "Then enjoy",
  rewardsEnjoyBoxContent:
    "View, select, and redeem rewards on your next purchase",
  rewardsSignUpButtonUrl: "https://enrollnow.vip/join/2317",
  rewardsSignUpButtonTitle: "Get Started",
  rewardsTrackRewardsUrl:
    "https://join.mywallet.deals/?id=fc074ce5-6846-4689-9e71-0afc84ee4208#/login",
  rewardsTrackRewardsButtonTitle: "Track Rewards",

  rewardFootNote:
    "*Rewards vary and may not be available at every location. Check your store for details.",

  /* Contact US */
  contact_url: "/contact-us",
  contact_us_desc1: "Questions about the dispensary experience? Check out our",
  contact_us_desc_faq_link: "FAQs",
  contact_us_desc2:
    "for fast answers to common questions. Otherwise, feel free to contact us directly.",
  contact_big_btn1: "Send us an email",
  contact_big_btn1_desc:
    "For general questions, please complete the form below and we will get back to you soon.",
  contact_big_btn2: "Call your Dispensary",
  contact_big_btn2_desc:
    "For product inquiries or questions about your local dispensary, please contact the store directly.",
  contact_email_form_title: "Send us a message and we’ll get back to you soon!",
  contact_email_btn_val: "Send",
  validationError:
    "*Required field. Please complete all required fields and press send.",
  contact_email_thanks_title: "Thank you for reaching out!",
  contact_email_thanks_message:
    "One of our Wellness Guides will be in touch as soon as possible.",

  /* Careers Page */
  careers: "CAREERS",
  career: "Careers",

  /* Shop Page*/
  location_desc1:
    "We are located in the Forum Shopping Center on 1400 Forum Blvd.",
  location_desc2:
    "Find us from the front of the Center by entering the interior hallway to the left of Eyes on Missouri. From the back entrance, we are the first store on the left-hand side.",

  img1_alt: "Storefront Image",
  img2_alt: "Store Hallway Image",

  // Rewards Page
  calc_title: "credit calculator tool",
  calc_subtitle: "Slide to reveal your rewards!",
  calc_spend: "SPEND $",
  calc_earn: "EARN",
  calc_equal: "$1 = 1",
  calc_49_upper: "Spend up to $49",
  calc_49_lower: "Get 1% Back",
  calc_149_upper: "Spend up to $149",
  calc_149_lower: "Get 5% Back",
  calc_299_upper: "Spend up to $299",
  calc_299_lower: "Get 10% Back",
  calc_599_upper: "Spend up to $599",
  calc_599_lower: "Get 15% Back",
  calc_999_upper: "Spend up to $999",
  calc_999_lower: "Get 20% Back",
  calc_1000_upper: "Spend $1000+",
  calc_1000_lower: "Get 25% Back",
}
